import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Spinner } from "./components/Spinner";
import AuthProvider from "./context/AuthContext";
const LazyApp = lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <Suspense fallback={<Spinner />}>
        <LazyApp />
      </Suspense>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
