import axios from "axios";
import { getToken, logout } from "./auth";

export const baseURL = process.env.REACT_APP_BASE_API2;

const API = axios.create({
  baseURL: baseURL + "/api/v2",
  withCredentials: true,
});

API.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      logout();
    }
  }
);

export { API };
